.container {
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
}
.container1 {
    height: 52px;
    width: 52px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container2 {
    display: flex;
    justify-content:space-between;
    text-align: center;
}
.container3 {
    flex-direction: row;
    height: 20px;
    line-height: 20px;
}
.middle {
    line-height: 48px;
}
.loveIcon {
    color: #fff;
    font-size: 24px;
}
.count {
    font-size: 12px;
    color: #696f7f;
    font-family: 'DINPro-Regular' !important;
}
.container3 .count {
    line-height: 40px;
    color: #696F7F;
    font-size: 14px;
}
.container .countLike {
    color: #f0506c
}
.iconContainer {
    margin: 0 auto;
    padding-top: 6px;
    height: 36px;
    width: 70px;
    border-radius: 36px;
    background-color: #D9DBDF;
    margin-bottom: 8px;
}
.containerLike {
    background-color: #FA6481;
}

.likeHover:hover {
    background-color: #F6F8FB;
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
}

.container1 .like {
    width: 26px;
    height: 26px;
    overflow: hidden;
}

.container1 .like > svg {
    margin-left: -7px;
    margin-top: -7px;
}

/* 新 like 组件样式 */
.like {
    font-size: 40px;
    color: #D9DBDF;
}

.likeActive {
    color: #FA6481;
}