.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 52px;
  width: 52px;
  line-height: 20px;
}

.middle {
  line-height: 44px;
}

.popoverContainer {
  padding: 20px;
  width: 480px;
}

.shareTitle {
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.shareUrl {
  border: 2px solid #F6F8FB;
  border-radius: 8px;
  height: 38px;
  width: 324px;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
}

.shareUrl .copyBtn {
  width: 60px;
  height: 28px;
  background-color: rgba(111, 64, 238, 0.1);
  color: #6F40EE;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 14px;
  position: absolute;
  right: 3px;
}
.shareWrap {
  display: flex;
  align-items: center;
}

.shareUrl .link {
  margin-left: 13px;
  width: 85%;
  white-space: nowrap;
  overflow-x: hidden;
  font-size: 12px;
  font-family: 'DINPro-Regular';
}

.shareUrl .link::after {
  position: absolute;
  top: 7px;
  right: 31px;
  display: block;
  content: '';
  width: 232px;
  height: 28px;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0.99) 79%);
}

.shareIcons {
  height: 32px;
  line-height: 32px;
  display: flex;
}

.shareTo {
  font-size: 14px;
  color: #696F7F;
  margin-right: 16px;
  flex-shrink: 0;
}

.IconItem {
  margin-right: 24px!important;
}

.count {
  font-size: 12px;
  color: #696f7f;
  font-family: 'DINPro-Regular' !important;
}

.share {
  font-size: 40px;
  color: #D9DBDF;
  width: 26px;
  height: 26px;
  overflow: hidden;
}

.share>svg {
  margin-left: -7px;
  margin-top: -7px;
}

@media (max-width: 960px) {
  .popoverContainer {
    padding: 10px;
    width: 345px;
  }

  .IconItem {
    margin-right: 14px;
  }

  .shareIcons {
    height: unset;
  }

  .shareUrl {
    width: unset;
  }

  .shareTitle {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}