.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    height: 52px;
    width: 52px;
    line-height: 20px;
}
.container3 {
    padding-top: 1px;
    height: 40px;
    width: auto;
    flex-direction: row;
}
.middle {
    line-height: 40px;
}
.count {
    font-size: 12px;
    color: #696F7F;
    line-height: 12px;
    font-family: 'DINPro-Regular' !important;
}
.container3 .count {
    line-height: 40px;
    color: #696F7F;
    font-size: 14px;
}
.container3 .imgContainer {
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
    justify-content: center;
}

.container3 .comment3Active,
.container3 .comment3:hover {
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
    background-color: #F6F8FB;
}

.container1 .comment1 {
    width: 26px;
    height: 26px;
    overflow: hidden;
}

.container1 .comment1 > svg {
    margin-left: -7px;
    margin-top: -9px;
}

/* 新 comment 组件样式 */
.comment1 {
    font-size: 40px;
    color: #D9DbDf;
}

.comment3 {
    color: #D9DBDF;
}
